@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bg-hero {
  background-image: url('./assets/images/background-image.jpg');
}

@keyframes float {
  0% {
    transform: translateY(-100px) scale(0);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    transform: translateY(100vh) scale(1);
    opacity: 0;
  }
}

.animate-float {
  animation: float linear infinite;
}
